import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { handleFormValidation } from '../../validation/validation-function'
import JoiValidationsSchema from '../../validation'
import Joi from 'joi'
import BackEndApis from '../../apis'
import { convertEncrypted } from '../../utils/functions/global-functions'
import CookiesStorageManager from '../../classes/cookies-storage'
import GlobalVariables from '../../utils/variables'
import { InputBox } from '../../components/ui/input/input-box'
import { Button } from '../../components/ui/button/button'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify';
import SelectBox from '../../components/ui/input/select-box'
import AppUrls from '../../routers/app-urls'


const SignUpPage = () => {

    const initialValues = {
        // role: '',
        email: '',
        password: '',
        firstName: "",
        lastName: "",
    }

    const validationSchema = Joi.object({
        // role: Joi.string().required(),
        email: JoiValidationsSchema.signUp.email,
        password: JoiValidationsSchema.signUp.password,
        firstName: JoiValidationsSchema.signUp.firstName,
        lastName: JoiValidationsSchema.signUp.lastName,
    })

    const navigate = useNavigate()
    // const Auth_Context = useAuth()

    const [stateFormData, setStateFormData] = React.useState(initialValues)
    const [stateErrors, setStateErrors] = React.useState(initialValues)

    const userRoles = ["ADMIN", "VENDOR"]


    // Note: New Address create function
    const handleSubmit = async (e) => {
        e.preventDefault()

        const validationResult = handleFormValidation(validationSchema, stateFormData)
        if (validationResult === null) {
            setStateErrors({})

            const apiResponse = await BackEndApis.Authentication.UserRegistration(stateFormData)
            if (apiResponse.status === 201) {
                toast.success('Registration successful')
                navigate(AppUrls.Auth.Url_Static_Login)
            }
            else {
                toast.error('Invalid Credentials')
                setStateFormData(initialValues)
            }
        }
        else {
            setStateErrors(validationResult)
        }
    }

    return (
        <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-1/2 xl:w-4/12 px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-gray-200 border-0">
                        <div className="flex-auto px-4 lg:px-10 py-10">
                            <h2 className='pb-8 font-semibold text-center text-xl'>Login Admin Account</h2>
                            <form onSubmit={handleSubmit} className='space-y-4'>
                                <InputBox
                                    fieldName={'Firstname'}
                                    type={'text'}
                                    name={'firstName'}
                                    placeholder={'firstname'}
                                    getValue={stateFormData}
                                    setValue={setStateFormData}
                                    error={stateErrors}
                                />

                                <InputBox
                                    fieldName={'Lastname'}
                                    type={'text'}
                                    name={'lastName'}
                                    placeholder={'lastname'}
                                    getValue={stateFormData}
                                    setValue={setStateFormData}
                                    error={stateErrors}
                                />

                                <InputBox
                                    fieldName={'Email'}
                                    type={'email'}
                                    name={'email'}
                                    placeholder={'email address'}
                                    getValue={stateFormData}
                                    setValue={setStateFormData}
                                    error={stateErrors}
                                />
                                <InputBox
                                    fieldName={'Password'}
                                    type={'password'}
                                    name={'password'}
                                    placeholder={'password'}
                                    getValue={stateFormData}
                                    setValue={setStateFormData}
                                    error={stateErrors}
                                />

                                <Button
                                    type='submit'
                                    btnName={'Sign Up'}
                                    btnClass={'w-full'}
                                />
                            </form>
                            <div className='flex justify-center items-center pt-4'>Already have an account? <Link to={AppUrls.Auth.Url_Static_Login} className='text-blue-500 underline font-medium ml-2'>Sign In</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage