const localUrl = localStorage.getItem('backendUrl') || "https://3jsapi.savitarainfotel.info/api"
const BASE_URL = process.env.REACT_APP_BACEND_URL || localUrl

const BackEndUrls = {
    Authentication: {
        register: `${BASE_URL}/register`,
        login: `${BASE_URL}/login`,
    },
    Projects: {
        create: `${BASE_URL}/add-project`,
        read: `${BASE_URL}/get-all-project`,
        single: `${BASE_URL}/get-project`,
        update_thumbnail: `${BASE_URL}/update-project`,
        delete_image: `${BASE_URL}/remove-image`,
        delete_project: `${BASE_URL}/delete-project`
    }
}

export default BackEndUrls