import { useLocation } from 'react-router-dom';

const useGetQuerys = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const params = {}
    for (let [key, value] of searchParams.entries()) {
        if (params[key]) {
            if (Array.isArray(params[key])) {
                params[key].push(value);
            } else {
                params[key] = [params[key], value];
            }
        } else {
            params[key] = value;
        }
    }
    if (Object.keys(params).length === 0) {
        return undefined;
    } else {
        return params;
    }
}

export default useGetQuerys