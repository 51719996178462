export const handleFormValidation = (errorSchema, formData) => {
    const validationResult = errorSchema.validate(formData, { abortEarly: false })
    if (validationResult.error) {
        const newErrors = {}
        validationResult.error.details.forEach((error) => {
            newErrors[error.path[0]] = error.message
        })
        return newErrors
    }
    else {
        return null
    }
}