import React from 'react'
import AuthContextProvider from './auth-context'
import HeaderContentProvider from './header-context'

const InitializationContext = ({ children }) => {
    return (
        <AuthContextProvider>
            <HeaderContentProvider>
                {children}
            </HeaderContentProvider>
        </AuthContextProvider>
    )
}

export default InitializationContext