import React from 'react'

export const PageLoader = () => {
    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-indigo-500"></div>
        </div>
    )
}

export const DashboardCardSkeleton = () => {
    return (
        <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
            <div className="flex-auto p-4 animate-pulse">
                <div className="flex flex-wrap">
                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1 space-y-3">
                        <div className="w-full h-4 bg-gray-200 rounded-full"></div>
                        <div className="w-full h-4 bg-gray-200 rounded-full"></div>
                    </div>
                    <div className="relative w-auto pl-4 flex-initial">
                        <div className="size-12 block bg-gray-200 rounded-full"></div>
                    </div>
                </div>
                <div className="w-full h-4 bg-gray-200 rounded-full mt-3"></div>
            </div>
        </div>
    )
}
