import Joi from "joi";

const JoiValidationsSchema = {
    login: {
        email: Joi.string().required().email({ tlds: { allow: ['com', 'net'] } }),
        password: Joi.string().min(8).max(15).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/).messages({
            'string.min': 'Password must be at least 8 characters long',
            'string.max': 'Password cannot be longer than 15 characters',
            'string.pattern.base': 'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
        }),
    },
    signUp: {
        firstName: Joi.string().required().min(2).max(20).lowercase(),
        lastName: Joi.string().required().min(2).max(20).lowercase(),
        email: Joi.string().required().email({ tlds: { allow: ['com', 'net'] } }),
        password: Joi.string().min(8).max(15).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/).messages({
            'string.min': 'Password must be at least 8 characters long',
            'string.max': 'Password cannot be longer than 15 characters',
            'string.pattern.base': 'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
        }),
    },
    category: {
        name: Joi.string().required().min(3).max(15),
        description: Joi.string().max(150).optional(),
        subCategory: Joi.string().required().min(3).max(15),
    },
    products: {
        name: Joi.string().required().min(5).max(100),
        price: Joi.string().required(),
        brand: Joi.string().required(),
        categoryName: Joi.string().required(),
        categoryId: Joi.string().required(),
        description: Joi.string().required(),
        colors: Joi.string().required(),
        quantity: Joi.string().required(),
    },
    order: {
        status: Joi.string().required()
    },
    profile: {
        firstName: Joi.string().required().min(2).max(20).lowercase(),
        lastName: Joi.string().required().min(2).max(20).lowercase(),
        email: Joi.string().required().email({ tlds: { allow: ['com', 'net'] } }),
        mobile: Joi.string().required().min(10).max(10),
        gender: Joi.string().required(),
        country: Joi.string().required(),
        state: Joi.string().required(),
        city: Joi.string().required(),
        profile: Joi.object().required().messages({ 'object.base': 'Choose your profile' }),
        password: Joi.string().min(8).max(15).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/).messages({
            'string.min': 'Password must be at least 8 characters long',
            'string.max': 'Password cannot be longer than 15 characters',
            'string.pattern.base': 'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
        }),
        confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({ 'any.only': 'Passwords do not match' }),
    },
    fullname: Joi.string().required().min(5).max(25).lowercase(),
    oldpassword: Joi.string().min(8).max(15),
    password: Joi.string().min(8).max(15).regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/).messages({
        'string.min': 'Password must be at least 8 characters long',
        'string.max': 'Password cannot be longer than 15 characters',
        'string.pattern.base': 'Password must contain at least one uppercase letter, one lowercase letter, and one special character'
    }),
    confirmPassword: Joi.string().required().valid(Joi.ref('password')).messages({ 'any.only': 'Passwords do not match' }),
    alternateMobile: Joi.string().length(10).pattern(/^\d+$/).optional().messages({
        'string.base': 'Alternate mobile number must be a string',
        'string.empty': 'Alternate mobile number cannot be empty',
        'string.length': 'Alternate mobile number must be exactly 10 digits long',
        'string.pattern.base': 'Alternate mobile number must contain only digits'
    }),
    address: Joi.string().required(),
    contactus: {
        subject: Joi.string().required(),
        messages: Joi.string().required()
    },
    addressForm: {
        zipcode: Joi.string().required().min(6).max(6),
        flatBlockNo: Joi.string().required().min(2),
        buildingArea: Joi.string().required().min(2),
        addressType: Joi.string().required(),
        isDefault: Joi.number(),
    },
    contactOTP: Joi.string().length(6).pattern(/^\d+$/).required().messages({
        'string.base': 'OTP must be a string',
        'string.empty': 'OTP is required',
        'string.length': 'OTP must be exactly 6 digits',
        'string.pattern.base': 'OTP must contain only digits'
    }),
}

export default JoiValidationsSchema