import React from 'react'
import { useNavigate } from 'react-router-dom';
import CookiesStorageManager from '../../classes/cookies-storage'
import GlobalVariables from '../../utils/variables'
import { toast } from 'react-toastify'
import { useAuth } from '../../hooks/useAuth'
import AppUrls from '../../routers/app-urls'

const LogoutPage = () => {

    const { logout } = useAuth()

    const navigate = useNavigate()
    const storage = new CookiesStorageManager()

    const handleSignOut = () => {
        const res = storage.DeleteStorageItem(GlobalVariables.CookiesAuthAccessKey)
        if (res === undefined) {
            toast.success('success', 'Logout Account', 'your account has been logged out successfully')
            logout()
            navigate(AppUrls.Auth.Url_Static_Login)
        }
        else {
            toast.error('error', 'Logout Account', 'your account has not been logged out successfully')
        }
    }

    React.useEffect(() => {
        handleSignOut()
    }, [])

    return (
        <div>Please Wait...</div>
    )
}

export default LogoutPage