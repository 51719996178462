import React from 'react'
import FetchImages from '../../assets/images'
import FetchIcons from '../../assets/icons'
import { Link } from 'react-router-dom'
import AppUrls from '../../routers/app-urls'

const PageNotFoundPage = () => {
    return (
        <div className="relative z-10 px-4 h-screen">
            <div className="bg-white w-full flex flex-col-reverse items-center justify-center shadow py-12">
                <div className="text-center">
                    <p className="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider text-gray-300">404</p>
                    <p className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider text-gray-300 mt-2">Page Not Found</p>
                    <p className="text-lg md:text-xl lg:text-2xl text-gray-500 my-12">Sorry, the page you are looking for could not be found.</p>
                    <Link to={AppUrls.Url_Static_Home} className="bg-blue-600 hover:bg-blue-700 text-gray-100 px-4 py-2 rounded w-fit">
                        <span>Return Home</span>
                    </Link>
                </div>
                <div className="w-fit h-fit">
                    <img src={FetchImages.Image_Not_Found} alt='not found' className='w-full h-full' />
                </div>
            </div>
        </div>
    )
}

export default PageNotFoundPage