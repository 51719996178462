import React from 'react';
import CookiesStorageManager from '../classes/cookies-storage';
import GlobalVariables from '../utils/variables';
import { convertDecrypted } from '../utils/functions/global-functions';
import BackEndApis from '../apis';
import FetchImages from '../assets/images';
import { jwtDecode } from 'jwt-decode';


export const AuthContext = React.createContext()

const AuthContextProvider = ({ children }) => {

    const [stateIsLogin, setStateIsLogin] = React.useState(false)
    const [stateLoginData, setStateLoginData] = React.useState(null)
    const storage = new CookiesStorageManager()


    // Note: handleAuthChecker is a check the authentication token is store or not
    const handleAuthChecker = async () => {
        const getAccessToken = storage.GetStorageItem(GlobalVariables.CookiesAuthAccessKey)
        const getDecodeAccessToken = convertDecrypted(getAccessToken)
        if (getDecodeAccessToken !== undefined) {
            const decodedToken = jwtDecode(getDecodeAccessToken)
            const expirationTime = decodedToken.exp;
            const currentTime = Date.now() / 1000;
            const isTokenExpired = expirationTime < currentTime;
            if (!isTokenExpired) {
                // const apiResponse = await BackEndApis.Authentication.GetSingleUser()
                setStateIsLogin(true)
                setStateLoginData({
                    email: decodedToken?.email,
                    userId: decodedToken?.loginId,
                    userType: decodedToken?.userType
                })
            }
            else {
                setStateIsLogin(false)
                setStateLoginData(null)
            }
        }
        else {
            setStateIsLogin(false)
            setStateLoginData(null)
        }
    }

    const handleLogout = () => {
        const getAccessToken = storage.DeleteStorageItem(GlobalVariables.CookiesAuthAccessKey)
        if (getAccessToken === undefined) {
            setStateIsLogin(false)
            setStateLoginData(null)
        }
    }

    React.useEffect(() => {
        handleAuthChecker()
    }, [])

    const value = React.useMemo(() => ({
        status: stateIsLogin,
        handleAuth: handleAuthChecker,
        logout: handleLogout,
        data: stateLoginData
    }), [stateIsLogin])

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}


export default AuthContextProvider