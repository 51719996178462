import { ApiGet, ApiPatch, ApiPost } from "../../services/apis-methods"
import BackEndUrls from "../../services/backend-urls"
import { handleApiResponse } from "../api-response"

class AuthenticationManager {
    constructor() {
        this.registrationUrl = BackEndUrls.Authentication.register
        this.loginUrl = BackEndUrls.Authentication.login
    }

    async UserRegistration(data) {
        try {
            const response = await ApiPost({ url: this.registrationUrl, body: data })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async UserLogin(data) {
        try {
            const response = await ApiPost({ url: this.loginUrl, body: data })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }
}

export default AuthenticationManager