import React from 'react'
import SidebarLayout from './sidebar'
import AdminNavbar from '../navbar/admin-navbar'
import { Outlet } from 'react-router-dom'

const AdminLayout = () => {
    return (
        <div>
            <SidebarLayout />
            <div className="relative md:ml-64 bg-blueGray-100">
                {/* <AdminNavbar /> */}
                <div className="px-4 md:px-10 mx-auto w-full py-12">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AdminLayout