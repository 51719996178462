import axios from "axios";
import GlobalVariables from "../utils/variables";
import { convertDecrypted } from "../utils/functions/global-functions";
import CookiesStorageManager from "../classes/cookies-storage";


const configHeaders = () => {
    const storage = new CookiesStorageManager()
    const encrypted_token = storage.GetStorageItem(GlobalVariables.CookiesAuthAccessKey)
    if (encrypted_token !== undefined) {
        const decrypt_token = convertDecrypted(encrypted_token)
        return {
            "authorization": `Bearer ${decrypt_token}`
        }
    }
    else {
        return {
            "Content-Type": "application/json",
        }
    }
}

export const ApiPost = async ({ url, body, responseType = 'json' }) => {
    try {
        return await axios.post(url, body, { headers: configHeaders(), responseType: responseType })
    }
    catch (error) {
        return error
    }
}

export const ApiGet = async ({ url, params = {} }) => {
    try {
        return await axios.get(url, { params, headers: configHeaders() })
    }
    catch (err) {
        return err.response
    }
}

export const ApiPatch = async ({ url, params, body }) => {
    try {
        return await axios.patch(url, body, { params, headers: configHeaders() });
    }
    catch (error) {
        return error.response
    }
}

export const ApiDelete = async ({ url, params = {}, body }) => {
    try {
        return await axios.delete(url, { params, data: body, headers: configHeaders() });
    } catch (error) {
        return error.response;
    }
};