import React from 'react'
import { Outlet } from 'react-router-dom'
import FetchImages from '../../assets/images'

const AuthLayout = () => {
    return (
        <main>
            <section className="w-full h-screen">
                <div className="w-full h-full bg-slate-800 bg-no-repeat bg-full" style={{ backgroundImage: `url(${FetchImages.Image_AuthBackground})` }}>
                    <Outlet />
                </div>
            </section>
        </main>
    )
}

export default AuthLayout