import React from 'react'
import { EachRender } from '../../../utils/functions/each-rende'
import { Show } from '../../../utils/functions/show-check'
import FetchIcons from '../../../assets/icons'


export const InputBox = ({ fieldName, name, type, placeholder, getValue = null, setValue, required = false, textBoxCss, labelCss, error, disabled = false }) => {
    return (
        <div className='w-full'>
            <label htmlFor={fieldName} className={`${labelCss} block mb-2 text-sm font-medium text-gray-900`}>{fieldName}</label>
            <input
                type={type}
                name={name}
                id={fieldName}
                placeholder={placeholder}
                required={required}
                title={fieldName}
                value={getValue !== null ? getValue[name] : ''}
                autoComplete='off'
                min={0}
                disabled={disabled}
                readOnly={disabled}
                onChange={(e) => setValue(prev => ({ ...prev, [name]: e.target.value }))}
                className={`${textBoxCss} bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 focus-visible:ring-1 focus-visible:ring-blue-600`}
            />
            {error && error[name] ? <span className='text-red-500 font-semibold text-sm'>{error[name]?.replaceAll('"', '')}</span> : ''}
        </div>
    )
}

export const TextBoxPassword = ({ fieldName, name, placeholder, getValue = null, setValue, required = false, textBoxCss, labelCss, error }) => {

    const validationData = [
        {
            title: "Minimum number of characters is 8.",
            rule: getValue[name]?.length >= 8
        },
        {
            title: "Should contain lowercase.",
            rule: /[a-z]/.test(getValue[name])
        },
        {
            title: "Should contain uppercase.",
            rule: /[A-Z]/.test(getValue[name])
        },
        {
            title: "Should contain numbers.",
            rule: /\d/.test(getValue[name])
        },
        {
            title: "Should contain special characters.",
            rule: /[!@#$%^&*]/.test(getValue[name])
        },
    ]

    return (
        <div className="relative flex-1">
            <label htmlFor={fieldName} className={`block mb-2 text-sm font-medium text-gray-900`}>{fieldName}</label>
            <input
                type="password"
                id={fieldName}
                placeholder={placeholder}
                required={required}
                title={fieldName}
                value={getValue !== null ? getValue[name] : ''}
                autoComplete='off'
                onChange={(e) => setValue(prev => ({ ...prev, [name]: e.target.value }))}
                className={`${textBoxCss} peer bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 focus-visible:ring-1 focus-visible:ring-blue-600`}
            />
            <div className="absolute mt-4 z-10 w-full bg-white shadow rounded-lg p-4 hidden peer-focus:block">
                <h4 className="text-sm font-semibold text-gray-800 mb-2">Your password must contain:</h4>
                <ul className="space-y-1 text-sm text-gray-500">
                    <EachRender of={validationData} render={(item, index) => (
                        <li className={`${item.rule ? 'text-teal-700' : 'text-red-700'} flex items-center gap-x-2 font-medium`} key={index}>
                            <Show>
                                <Show.When isTrue={item.rule}><FetchIcons.Icon_MdCheck className='w-4 h-4' /></Show.When>
                                <Show.Else><FetchIcons.Icon_IoMdClose className='w-4 h-4' /></Show.Else>
                            </Show>
                            {item.title}
                        </li>
                    )} />
                </ul>
            </div>
            {error && error[name] ? <span className='text-red-500 font-semibold text-sm'>{error[name]?.replaceAll('"', '')}</span> : ''}
        </div>
    )
}