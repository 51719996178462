import React from 'react'
import { Show } from '../../utils/functions/show-check';
import { PageLoader } from '../../components/ui/loading';
import { EachRender } from '../../utils/functions/each-rende';
import BackEndApis from '../../apis';
import ProjectForm from './project-form';
import { toast } from 'react-toastify';
import FetchIcons from '../../assets/icons';
import AppUrls from '../../routers/app-urls';
import { RefreshButton } from '../../components/ui/button/button';
import GlobalVariables from '../../utils/variables';

const ProjectPage = () => {

    // const { handleMessage } = useHeader()
    // const { data } = useAuth()

    const [stateProjects, setStateProjects] = React.useState(null)
    const [stateLoading, setStateLoading] = React.useState(false)

    // Note: handleFetchProducts function is a get all B2C products from api
    const handleFetchProducts = async () => {
        setStateLoading(true)
        const apiResponse = await BackEndApis.Projects.GetAllProjects()
        setStateProjects(apiResponse.data)
        setStateLoading(false)
    }

    React.useEffect(() => {
        // handleMessage('business to customer products')
        handleFetchProducts()
    }, [])

    const trashButtonClick = async (projectId) => {
        const apiResponse = await BackEndApis.Projects.DeleteProject(projectId)
        if (apiResponse.status === 200) {
            toast.success("Project deleted successfully")
        }
        else {
            toast.error("Something went wrong")
        }
        handleFetchProducts()
    }

    const handleUrlCopied = (item) => {
        const url = `${GlobalVariables.DomainName}${AppUrls.Url_Project_Viewer}?pname=${item.projectName}&pod=${item._id}&room=${1}`
        navigator.clipboard.writeText(url)
        toast.success("Project link copied successfully")
    }

    const handleViewRoomNewTab = (item) => {
        const url = `${GlobalVariables.DomainName}${AppUrls.Url_Project_Viewer}?pname=${item.projectName}&pod=${item._id}&room=${1}`
        window.open(url, '_blank')
    }


    return (
        <div className='relative z-10 bg-white min-h-40 shadow rounded '>
            <div className='py-3 px-4 text-center border-b border-gray-300 flex justify-between items-center'>
                <h2 className='text-xl font-semibold'>Projects</h2>
                <div className='flex justify-between items-center gap-6'>
                    <RefreshButton btnClick={handleFetchProducts} />
                    <ProjectForm refresh={handleFetchProducts} />
                </div>
            </div>
            <Show>
                <Show.When isTrue={stateLoading === false && stateProjects && stateProjects !== null}>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-4 px-5 gap-4'>
                        <EachRender of={stateProjects} render={(item, index) => (
                            <div className="relative rounded-md bg-white p-3 m-2 shadow shadow-slate-800/30 max-w-96 w-full" key={index}>
                                <div className="relative flex items-end overflow-hidden rounded group">
                                    <img
                                        src={item.thumbnail}
                                        alt="item"
                                        style={{ width: "100%", height: "200px", objectFit: "cover" }}
                                    />
                                    <div className='w-full h-full bg-slate-800/50 absolute top-0 left-0 z-20 hidden group-hover:flex justify-center items-center gap-3'>
                                        <ProjectForm projectData={item} editStatus={true} refresh={handleFetchProducts} />
                                        <FetchIcons.Icon_IoMdTrash className='w-6 h-6 text-white cursor-pointer' title='delete project' onClick={() => trashButtonClick(item._id)} />
                                    </div>
                                </div>
                                <div className='flex justify-between mt-5 px-3'>
                                    <h2 className="text-slate-700 mt-2 font-semibold no-underline">{item.projectName}</h2>
                                    <div className='flex gap-3 justify-center items-center'>
                                        <div className='group text-white bg-white hover:bg-blue-700 border-2 hover:border-blue-700 focus:border-none focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 text-center cursor-pointer' onClick={() => handleUrlCopied(item)}>
                                            <FetchIcons.Icon_IoMdLink className='w-6 h-6 text-black group-hover:text-white' />
                                        </div>
                                        <div className='text-white py-2.5 bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-3 py-1 text-center cursor-pointer' onClick={() => handleViewRoomNewTab(item)}>
                                            <FetchIcons.Icon_ImNewTab className='w-4 h-4 text-white' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )} />
                    </div>
                </Show.When>
                <Show.When isTrue={stateLoading === false || stateProjects && stateProjects == null}>
                    <div className='min-h-40 flex justify-center items-center'>
                        <h3 className='text-4xl text-gray-500 font-semibold capitalize'>Project not found</h3>
                    </div>
                </Show.When>
                <Show.Else>
                    <div className='relative z-10 min-h-40 flex justify-center items-center'>
                        <PageLoader />
                    </div>
                </Show.Else>
            </Show>
        </div>
    )
}

export default ProjectPage