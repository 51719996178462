import React from 'react'
import Dialog from '@mui/material/Dialog'
import { Button } from '../../components/ui/button/button'
import { MuiTransitionUp } from '../../components/mui/mui-transition'
import { toast } from 'react-toastify'
import BackEndApis from '../../apis'
import { handleFormValidation } from '../../validation/validation-function'
import Joi from 'joi'
import FetchIcons from '../../assets/icons'
import { InputBox } from '../../components/ui/input/input-box'
import { customChooseImage } from '../../utils/functions/global-functions'
import { Show } from '../../utils/functions/show-check'

const ProjectForm = ({ refresh, projectData = null, editStatus = false }) => {

    const initialValues = {
        projectName: "",
        thumnails: "",
    }

    const validationSchema = Joi.object({
        projectName: Joi.string().required(),
    })

    const [stateOpen, setStateOpen] = React.useState(false)
    const [stateFormData, setStateFormData] = React.useState(initialValues)
    const [stateErrors, setStateErrors] = React.useState(initialValues)

    // Note: handleOnSubmit function is submit the category form
    const handleOnSubmit = async (e) => {
        e.preventDefault()

        const validationResult = handleFormValidation(validationSchema, { projectName: stateFormData.projectName })
        if (validationResult === null) {
            setStateErrors({})

            if (editStatus === true) {
                handleEditProject()
            }
            else {
                handleCreateProject()
            }

            setStateFormData(initialValues)
            setStateOpen(false)
            refresh()
        }
        else {
            setStateErrors(validationResult)
        }
    }

    // Note: handleCreateProject function is create new project function
    const handleCreateProject = async () => {
        const formData = new FormData()

        formData.append('projectName', stateFormData.projectName)
        formData.append('thumbnail', stateFormData.thumnails)

        try {
            const apiResponse = await BackEndApis.Projects.CreateProject(formData)
            if (apiResponse.status === 201) {
                toast.success("Project Create Successfully.")
            }
            else {
                toast.error("Product Not Created.")
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    // Note: handleEditProject function is edit project name and thumbnail
    const handleEditProject = async () => {
        const formData = new FormData()

        formData.append('projectName', stateFormData.projectName)
        formData.append('thumbnail', stateFormData.thumnails)

        const apiResponse = await BackEndApis.Projects.UpdateProject(projectData._id, formData)
        if (apiResponse.status === 200) {
            toast.success("Project Update Successfully.")
        }
        else {
            toast.error("Product Not Updated.")
        }
    }

    // Note: handleOpenDialog function is a open dialog
    const handleOpenDialog = () => {
        setStateOpen(true)
    }

    React.useEffect(() => {
        if (projectData !== null && editStatus === true) {
            setStateFormData(prev => ({ ...prev, projectName: projectData?.projectName }))
        }
    }, [projectData])

    return (
        <div>
            <Show>
                <Show.When isTrue={editStatus === true}>
                    <FetchIcons.Icon_MdEdit className='w-6 h-6 text-white cursor-pointer' title='edit thumnail' onClick={handleOpenDialog} />
                </Show.When>
                <Show.Else>
                    <Button btnName={'New Project'} btnClick={handleOpenDialog} />
                </Show.Else>
            </Show>
            <Dialog
                keepMounted
                fullWidth
                open={stateOpen}
                TransitionComponent={MuiTransitionUp}
                maxWidth="md"
            >
                <div className='w-full'>
                    <div className='bg-blue-600 py-4 px-8 flex justify-between items-center'>
                        <h3 className='text-xl font-semibold capitalize text-white'>
                            <Show>
                                <Show.When isTrue={editStatus === true}>
                                    Edit Project
                                </Show.When>
                                <Show.Else>
                                    New Project
                                </Show.Else>
                            </Show>
                        </h3>
                        <FetchIcons.Icon_IoClose className='text-3xl text-white cursor-pointer' onClick={() => setStateOpen(false)} />
                    </div>
                    <form onSubmit={handleOnSubmit} method='post'>
                        <div className='grid grid-cols-12 p-5'>
                            <div className='col-span-12 mb-3'>
                                <InputBox
                                    fieldName={'Project Name'}
                                    type={'text'}
                                    name={'projectName'}
                                    placeholder={'project name'}
                                    getValue={stateFormData}
                                    setValue={setStateFormData}
                                    error={stateErrors}
                                />
                            </div>
                            <div className='col-span-12 mb-3'>
                                <label htmlFor="" className='block mb-2 text-sm font-medium'>Choose Project Thumnails</label>
                                <input
                                    type="file"
                                    name="thumnails"
                                    onChange={(e) => {
                                        const res = customChooseImage(e)
                                        console.log(res);
                                        res[0].error === "" ? setStateFormData(prev => ({ ...prev, thumnails: res[0].file })) : setStateErrors(prev => ({ ...prev, thumnails: res[0].error }))
                                    }}
                                />
                            </div>
                            <div className='col-span-12'>
                                <Button btnName={'Submit'} type='submit' />
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default ProjectForm