import React from "react"
import FetchIcons from "../../../assets/icons"

export const Button = ({ btnName, type = "button", btnClick, btnClass }) => {
    return (
        <button
            type={type}
            onClick={btnClick}
            className={`text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${btnClass}`}>
            {btnName}
        </button>
    )
}

export const ButtonWithLoader = ({ btnClass }) => {
    return (
        <button
            type={'button'}
            className={`${btnClass} flex justify-center items-center gap-3 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>
            <span className="flex h-4 w-4 animate-spin rounded-full border-2 border-solid border-current items-center border-e-transparent text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-white"></span>
            <span className="text-base font-semibold">Loading</span>
        </button>
    )
}


export const WhiteButton = ({ btnName, type = "button", btnClick }) => {
    return (
        <button
            type={type}
            onClick={btnClick}
            className={`inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}>
            {btnName}
        </button>
    )
}


export const RefreshButton = ({ btnClick }) => {

    // const [stateAnimation, setStateAnimation] = React.useState(false)   // Note: false mease animation is stored

    // const handleRefreshBtn = () => {
    //     setStateAnimation(true)
    //     btnClick()
    //     setTimeout(() => {
    //         setStateAnimation(false)
    //     }, 1000)
    // }

    return (
        <button onClick={btnClick} className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
            {/* <FetchIcons.Icon_LuRefreshCcw className={`w-5 h-5 ${stateAnimation ? 'animate-refresh-spin' : ''} `} /> */}
            <FetchIcons.Icon_LuRefreshCcw className={`w-5 h-5`} />
            <span className="mx-1 hidden lg:block">Refresh</span>
        </button>
    )
}