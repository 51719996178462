import { convertDecrypted } from "../utils/functions/global-functions"

export const handleApiResponse = (res = null) => {
    const statusCode = [404, 401, 406, 409]
    const errorstatusCode = [500]
    if (res !== null) {
        const resData = {}
        if (statusCode.includes(res?.response?.data?.status)) {
            resData.status = res.response.data.status
            resData.message = res.response.data.message
            resData.data = null
        }
        else if (errorstatusCode.includes(res?.response?.data?.error?.status || res?.response?.status)) {
            resData.status = res.response.data.error.status
            resData.message = res.response.data.error.message
            resData.data = null
        }
        else {
            // const convertDecryptedData = convertDecrypted(res.data.data, 'backend')
            resData.status = res.status
            resData.statusText = res.statusText
            resData.message = res.data.message
            // resData.data = convertDecryptedData
            resData.data = res.data.data
        }
        console.log("helder function", resData);

        return resData;
    }
    return null
}