import React from 'react';

export const HeaderContentContext = React.createContext()

const HeaderContentProvider = ({ children }) => {

    const [stateMessage, setStateMessage] = React.useState(null)

    const handleChangeMessage = (message = "") => {
        setStateMessage(message)
    }

    const value = React.useMemo(() => ({
        message: stateMessage,
        handleMessage: handleChangeMessage
    }), [stateMessage])

    return (
        <HeaderContentContext.Provider value={value}>{children}</HeaderContentContext.Provider>
    )
}


export default HeaderContentProvider