const AppUrls = {
    Url_Static_Home: '/',
    Url_Not_Found: '/not-found/',
    Url_Project_Viewer: '/project/viewer/',
    Url_Project_Room_Viewer: '/project/room/viewer/',
    Auth: {
        Url_Static_Login: '/auth/login/',
        Url_Static_Logout: '/auth/logout/',
        Url_Static_SignUp: '/auth/signup/',
    },
}

export default AppUrls