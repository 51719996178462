import React from 'react'
import BackEndApis from '../../apis'
import FetchIcons from '../../assets/icons'
import { Dialog } from '@mui/material'
import { MuiTransitionUp } from '../../components/mui/mui-transition'
import { Button, WhiteButton } from '../../components/ui/button/button'
import { handleFormValidation } from '../../validation/validation-function'
import Joi from 'joi'
import { toast } from 'react-toastify'
import { customChooseImage } from '../../utils/functions/global-functions'
import { InputBox } from '../../components/ui/input/input-box'
import { EachArrayRender } from '../../utils/functions/each-rende'
import { Show } from '../../utils/functions/show-check'

const NewImagesForm = ({ projectId, projectName, refresh, changeImageCounter }) => {

    const initialValues = {
        images: "",
    }

    const [stateOpen, setStateOpen] = React.useState(false)
    const [stateFormData, setStateFormData] = React.useState(initialValues)
    const [stateErrors, setStateErrors] = React.useState(initialValues)
    const [stateImageCount, setStateImageCount] = React.useState(1)


    // Note: handleOnSubmit function is submit the category form
    const handleOnSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        for (let i = 1; i <= 5; i++) {
            if (stateFormData[`image${i}`] !== undefined) {
                formData.append(`images`, stateFormData[`image${i}`]);
            }

            if (stateFormData[`imageTitle${i}`] !== undefined) {
                formData.append(`imagesData[${i - 1}][title]`, stateFormData[`imageTitle${i}`]);
            }
        }
        formData.append(`projectName`, projectName);

        try {
            const apiResponse = await BackEndApis.Projects.UpdateProject(projectId, formData)
            if (apiResponse.status === 200) {
                changeImageCounter()
                toast.success("Project Image Added Successfully.")
            }
            else {
                toast.error("Product Image Not Added.")
            }
        }
        catch (err) {
            console.error(err)
        }
        refresh()
        setStateFormData({ images: "" })
        setStateOpen(false)
    }

    // Note: The 'handelChooseImages' function allows users to select images. It checks the images and updates the image in the 'stateForm' state.
    const handelChooseImages = (e) => {
        const res = customChooseImage(e)
        console.log(e.target.name, res)
        const withOutErrorFile = res.filter(item => item.error === "").map(item => item.file)
        setStateFormData(prev => ({ ...prev, [e.target.name]: withOutErrorFile[0] }))
    }

    return (
        <div>
            <div className='shadow rounded bg-white shadow-slate-800 w-40 h-fit p-2 cursor-pointer' onClick={() => setStateOpen(true)}>
                <div className='w-full h-full border-2 border-blue-500 border-dashed py-4 rounded flex flex-col justify-center items-center'>
                    <div className='bg-blue-500/50 text-white rounded-full p-3'>
                        <FetchIcons.Icon_FiPlus className='w-6 h-6' />
                    </div>
                    {/* <h2 className='text-center text-sm text-black pt-2 px-4'>Add Image</h2> */}
                </div>
            </div>
            <Dialog
                keepMounted
                fullWidth
                open={stateOpen}
                TransitionComponent={MuiTransitionUp}
                maxWidth="md"
            >
                <div className='w-full'>
                    <form onSubmit={handleOnSubmit} method='post'>
                        <div className='bg-blue-600 py-4 px-8 flex justify-between items-center'>
                            <h3 className='text-xl font-semibold capitalize text-white'>Add Images</h3>
                            <WhiteButton btnName={'Submit'} type='submit' />
                            <FetchIcons.Icon_IoClose className='text-3xl text-white cursor-pointer' onClick={() => setStateOpen(false)} />
                        </div>
                        <div className='grid grid-cols-12 p-5'>
                            <div className='col-span-12 mb-3'>
                                <label htmlFor="" className='block mb-2 text-sm font-medium'>Project Name</label>
                                <input
                                    type="text"
                                    name="projectname"
                                    readOnly={true}
                                    disabled={true}
                                    value={projectName}
                                    className='bg-gray-200/50 w-full py-2 px-3 rounded'
                                />
                            </div>
                            <div className='col-span-12 mb-3'>
                                <EachArrayRender size={stateImageCount} render={(item, index) => (
                                    <div className='flex justify-between items-center gap-4' key={index}>
                                        <div className='w-full mb-3'>
                                            <InputBox
                                                fieldName={'Image Name'}
                                                type={'text'}
                                                name={`imageTitle${index + 1}`}
                                                placeholder={'project name'}
                                                getValue={stateFormData}
                                                setValue={setStateFormData}
                                                error={stateErrors}
                                            />
                                        </div>
                                        <div className='w-full mb-3 cursor-pointer select-none'>
                                            <label className='block mb-2 text-sm font-medium'>Choose Project Images</label>
                                            <Show>
                                                <Show.When isTrue={stateFormData[`image${index + 1}`] !== undefined && stateFormData[`image${index + 1}`]?.length !== 0}>
                                                    <div className='relative bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg flex justify-between items-center w-full h-[42px] p-2.5'>
                                                        <h3 className='text-gray-400'>{stateFormData[`image${index + 1}`]?.name?.slice(0, 50)}</h3>
                                                        <FetchIcons.Icon_IoClose className='w-5 h-5 cursor-pointer' onClick={() => setStateFormData(prev => ({ ...prev, [`image${index + 1}`]: "" }))} />
                                                    </div>
                                                </Show.When>
                                                <Show.Else>
                                                    <div className='flex flex-col'>
                                                        <input type='file' name={`image${index + 1}`} onChange={handelChooseImages} />
                                                        {stateErrors && stateErrors[`image${index + 1}`] ? <span className='text-red-500 font-semibold text-sm'>{stateErrors[`image${index + 1}`].toString()?.replaceAll('"', '')}</span> : ''}
                                                    </div>
                                                </Show.Else>
                                            </Show>
                                        </div>
                                    </div>
                                )} />
                                <Show>
                                    <Show.When isTrue={stateImageCount < 5}>
                                        <div className='flex flex-col justify-center items-center py-4'>
                                            <WhiteButton type='button' btnName='Add Images' btnClick={() => setStateImageCount(stateImageCount + 1)} />
                                            {stateErrors && stateErrors['images'] ? <span className='text-red-500 font-semibold text-sm mt-4'>{stateErrors['images'].toString()?.replaceAll('"', '')}</span> : ''}
                                        </div>
                                    </Show.When>
                                </Show>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default NewImagesForm