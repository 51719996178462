import React from 'react'
import * as THREE from 'three'
import useGetQuerys from '../../hooks/useQuery'
import BackEndApis from '../../apis'
import { Show } from '../../utils/functions/show-check'
import { useNavigate } from 'react-router-dom'
import AppUrls from '../../routers/app-urls'
import NewImagesForm from './new-images'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FetchIcons from '../../assets/icons'
import { toast } from 'react-toastify'
import { useAuth } from '../../hooks/useAuth'
import { ChangeWebTitle } from '../../utils/functions/global-functions'
import GlobalVariables from '../../utils/variables'


const ProjectViewer = ({ rooms = false }) => {

    const containerRef = React.useRef(null)
    const cameraRef = React.useRef(null)
    const rendererRef = React.useRef(null)
    const meshRef = React.useRef(null)

    const querys = useGetQuerys()
    const navigate = useNavigate()
    const user = useAuth()

    const [stateLoading, setStateLoading] = React.useState(true)
    const [stateProject, setStateProject] = React.useState(null)
    const [stateActiveImage, setStateActiveImage] = React.useState(null)
    const [stateRoomMenu, setStateRoomMenu] = React.useState({})
    const [stateProjectImageCount, setStateProjectImageCount] = React.useState(0)
    const [stateThreeRotateCursor, setStateThreeRotateCursor] = React.useState(false)

    const fetchProject = async (pid) => {
        const apiResponse = await BackEndApis.Projects.GetSingleProject(pid || querys?.pod)
        if (apiResponse.data !== undefined) {
            setStateProject(apiResponse.data)

            const roomTitle = apiResponse?.data.imagesData.length !== 0 ? apiResponse?.data.imagesData[querys.room - 1]?.title + "in" : ''

            ChangeWebTitle(`${roomTitle} ${apiResponse.data.projectName}`)

            setStateProjectImageCount(apiResponse?.data?.imagesData.length)

            apiResponse.data?.imagesData?.map(item => setStateRoomMenu(prev => ({ ...prev, [item.title]: false })))

            handleRender3dModel(apiResponse?.data?.imagesData[querys.room - 1]?.image)
            // handleRender3dModel("http://localhost:7000/images/projectImages/images-1715256692211-484415080.jpg")
            setStateLoading(false)
        }
        else {
            setStateProject(null)
        }
    }

    const handleRemoveImage = async (roomIndex, roomTitle) => {
        handleCollapseMenu(roomTitle)
        const apiResponse = await BackEndApis.Projects.DeleteProjectImage(querys?.pod, roomIndex)
        if (apiResponse.status === 200) {
            setStateProjectImageCount(stateProjectImageCount - 1)
            toast.success("Image deleted successfully")
            if (roomIndex === 0) {
                window.location.reload()
            }
        }
        else {
            toast.error("Something went wrong")
        }

        fetchProject(querys?.pod)
    }

    const handleLinkCopied = (roomIndex, roomTitle) => {
        const url = `${GlobalVariables.DomainName}${AppUrls.Url_Project_Room_Viewer}?pname=${stateProject.projectName || querys?.pname}&rname=${roomTitle}&pod=${stateProject._id || querys?.pod}&room=${roomIndex}`
        navigator.clipboard.writeText(encodeURI(url))
        toast.success("Link copied successfully")
        handleCollapseMenu(roomTitle)
    }

    const handleCollapseMenu = (roomTitle) => {
        if (stateRoomMenu.hasOwnProperty(roomTitle)) {
            setStateRoomMenu((prevStatus) => ({
                ...Object.fromEntries(Object.entries(prevStatus).map(([key, value]) => [key, key === roomTitle ? !value : false]))
            }))
        }
        else {
            setStateRoomMenu((prevStatus) => ({
                ...Object.fromEntries(Object.entries(prevStatus).map(([key, value]) => [key, false]))
            }))
        }
    }

    const handleRender3dModel = (images = null) => {
        if (images !== null) {
            console.log(images);
            setStateActiveImage(images)

            // Create a new scene
            const scene = new THREE.Scene()

            // Create a new camera
            const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
            camera.rotation.y = Math.PI // Rotate the camera to face the back of the sphere
            cameraRef.current = camera

            // Create a new renderer
            const renderer = new THREE.WebGLRenderer()
            renderer.setSize(window.innerWidth, window.innerHeight)
            rendererRef.current = renderer
            containerRef.current.appendChild(renderer.domElement)

            // Create a sphere geometry
            const geometry = new THREE.SphereGeometry(500, 60, 40)
            geometry.scale(-1, 1, 1) // Invert the geometry to make it inside out

            // Load the 360 image texture
            const texture = new THREE.TextureLoader().load(images)

            // Create a material with the texture
            const material = new THREE.MeshBasicMaterial({ map: texture })

            // Create a mesh with the sphere geometry and material
            const mesh = new THREE.Mesh(geometry, material)
            meshRef.current = mesh

            // Add the mesh to the scene
            scene.add(mesh)

            // Render the scene
            const animate = () => {
                requestAnimationFrame(animate)
                renderer.render(scene, cameraRef.current)
            }
            animate()

            // Handle window resize
            const onWindowResize = () => {
                camera.aspect = window.innerWidth / window.innerHeight
                camera.updateProjectionMatrix()
                renderer.setSize(window.innerWidth, window.innerHeight)
            }

            window.addEventListener('resize', onWindowResize)

            // Handle mouse drag to rotate the image horizontally
            let isDragging = false
            let previousMousePosition = { x: 0, y: 0 }
            const handleMouseDown = (event) => {
                isDragging = true
                previousMousePosition = {
                    x: event.clientX,
                    y: event.clientY
                }
            }

            const handleMouseMove = (event) => {
                if (isDragging) {
                    const deltaMove = event.clientX - previousMousePosition.x
                    const rotationSpeed = 0.001     // rotationSpeed is controlled routation speed speed
                    cameraRef.current.rotation.y += deltaMove * rotationSpeed
                    previousMousePosition = { x: event.clientX, y: event.clientY }
                    renderer.render(scene, cameraRef.current) // Render the scene with updated camera rotation
                }
            }

            const handleMouseUp = () => {
                isDragging = false
            }

            containerRef.current.addEventListener('mousedown', handleMouseDown)
            containerRef.current.addEventListener('mousemove', handleMouseMove)
            containerRef.current.addEventListener('mouseup', handleMouseUp)
        }
    }


    React.useEffect(() => {
        if (querys !== undefined) {
            fetchProject(querys?.pod)
        }
        else {
            navigate(AppUrls.Url_Static_Home)
        }
    }, [])


    React.useEffect(() => {
        if (stateProject && stateProject !== null) {
            handleRender3dModel(stateProject.images[querys?.room - 1])
        }
    }, [querys?.room])


    return (
        <div className='w-screen h-screen overflow-hidden relative' onContextMenu={(e) => e.preventDefault()}>
            <div
                id='threeDRender'
                ref={containerRef}
                onMouseDown={() => { setStateThreeRotateCursor(true); handleCollapseMenu() }}
                onMouseUp={() => { setStateThreeRotateCursor(false); handleCollapseMenu() }}
                className={`${stateThreeRotateCursor ? 'cursor-grabbing' : 'cursor-grab'} w-full h-full overflow-hidden`}
            ></div>
            <Show>
                <Show.When isTrue={rooms}>
                    <div className='absolute -bottom-12 md:bottom-0 left-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-y-0 w-full flex justify-center items-center'>
                        <div className={`${stateProjectImageCount !== 0 ? 'w-full md:w-4/5 xl:w-1/2 justify-between' : 'w-full justify-center'} gap-8 flex  items-center`}>
                            <Show>
                                <Show.When isTrue={stateLoading === false && stateProject && stateProject !== null && stateProject?.imagesData?.length !== 0}>

                                    {/* All Rooms */}
                                    <div className='w-1/2 md:w-3/4 flex justify-center items-center gap-4'>
                                        <Swiper
                                            navigation={false}
                                            modules={[Navigation]}
                                            className="flex justify-center items-center w-full"
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 20
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 15
                                                },
                                                1020: {
                                                    slidesPerView: Math.min(3, stateProject?.imagesData?.length),
                                                    spaceBetween: 30
                                                }
                                            }}
                                        >
                                            {
                                                stateProject && stateProject?.imagesData?.map((item, index) => (
                                                    <SwiperSlide key={index} style={{ height: "208px", alignItems: "center" }}>
                                                        <div className={`w-40 h-fit shadow-lg p-2 rounded ${stateActiveImage && stateActiveImage === item.image ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}>
                                                            <a href={`${AppUrls.Url_Project_Viewer}?pname=${querys.pname}&pod=${querys.pod}&room=${index + 1}`} className='relative cursor-pointer'>
                                                                <img src={item.image} alt="not found" className='w-full h-full object-cover select-none' />
                                                            </a>
                                                            <div className='flex justify-between items-center pt-2'>
                                                                <h2 className='text-center text-sm'>{item.title}</h2>

                                                                <div className='relative'>
                                                                    <FetchIcons.Icon_PiDotsThreeOutlineFill className='w-4 h-4 cursor-pointer' onClick={() => handleCollapseMenu(item.title)} />

                                                                    {/* room menus options */}
                                                                    <Show>
                                                                        <Show.When isTrue={stateRoomMenu[item.title]}>

                                                                            <div className='absolute bottom-5 right-0 w-40 bg-white shadow shadow-slate-800/20 rounded-md overflow-hidden'>
                                                                                <div className='flex justify-start items-center gap-3 py-3 px-4 cursor-pointer select-none hover:bg-gray-100' onClick={() => handleLinkCopied(index === 0 ? 1 : index + 1, item.title)}>
                                                                                    <FetchIcons.Icon_IoMdLink className='w-4 h-4 cursor-pointer text-black' />
                                                                                    <span className='text-base font-medium text-black'>Copied Link</span>
                                                                                </div>
                                                                                <Show>
                                                                                    <Show.When isTrue={index !== querys?.room - 1 || stateProjectImageCount === 1}>
                                                                                        <div className='flex justify-start items-center gap-3 py-3 px-4 cursor-pointer select-none hover:bg-gray-100' onClick={() => handleRemoveImage(index === 0 ? 0 : index, item.title)}>
                                                                                            <FetchIcons.Icon_IoMdTrash className='w-4 h-4 cursor-pointer text-black' />
                                                                                            <span className='text-base font-medium text-black'>Delete</span>
                                                                                        </div>
                                                                                    </Show.When>
                                                                                    <Show.Else>
                                                                                        <div className='flex justify-start items-center gap-3 py-3 px-4 select-none hover:bg-gray-100'>
                                                                                            <FetchIcons.Icon_IoMdTrash className='w-4 h-4 cursor-pointer text-gray-300' />
                                                                                            <span className='text-base font-medium text-gray-300'>Delete</span>
                                                                                        </div>
                                                                                    </Show.Else>
                                                                                </Show>
                                                                            </div>
                                                                        </Show.When>
                                                                    </Show>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                    </div>

                                </Show.When>
                            </Show>

                            {/* New Room Button */}
                            <Show>
                                <Show.When isTrue={user && user && user?.status === true}>
                                    <div className='flex justify-around items-center gap-6 h-52 w-1/2 md:w-1/4'>
                                        <NewImagesForm
                                            projectId={querys?.pod}
                                            projectName={querys?.pname}
                                            refresh={fetchProject}
                                            changeImageCounter={() => setStateProjectImageCount(stateProjectImageCount + 1)}
                                        />
                                    </div>
                                </Show.When>
                            </Show>
                        </div>
                    </div>
                </Show.When>
            </Show>
        </div>
    )
}

export default ProjectViewer