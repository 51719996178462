const GlobalVariables = {
    CookiesAuthAccessKey: '_cs3DauidAwAT',      // Note: Cookies Storage ID Authentication With Access Token

    LocalStorageWishlistKey: '_wdd',        // Note: Wishlist Duplicates Data
    LocalStorageCartKey: '_cdpid',          // Note: Cart Duplicate Product Id

    ForgotPasswordSessionKey: '_ssFPsKsEt',  // Note: Session Storage Forgot Password Session Key Store Encode Token
    ForgotPasswordLocalKey: '_lsFPsTksEt',  // Note: Local Storage Forgot Password Session Token Key Store Encode Token

    EmailVerifySessionKey: '_ssReVTksEt',  // Note: Session Storage Registration Email Verify Token Key Store Encode Token
    EmailVerifyLocalKey: '_lsReVTksEt',  // Note: Local Storage Registration Email Verify Token Key Store Encode Token

    ContactUsLocalKey: '_lsCuLDd',  // Note: Local Storage Contact us Local Duplicate data
    ContactUsSessionKey: '_ssCuSgTk',  // Note: Session Storage Contact us Session generate Tempery key

    ProjectTypeB2C: "B2C",
    ProjectTypeB2B: "B2B",
    DomainName: "https://3js.savitarainfotel.info",

    Orders: {
        Status_Order_Placed: "Order Placed",    // Note: this is a order placed status 
        Status_Dispatch: "Dispatch",    // Note: this is a order dispatch status 
        Status_Shipped: "Shipped",  // Note: this is a order shipped status 
        Status_Delivered: "Delivered",  // Note: this is a order delivered status,
        Status_Cancelled: "Cancelled",  // Note: this is a order cancelled status,
    },

    Roles: {
        RoleAdmin: "ADMIN",
        RoleVender: "VENDOR",
        RoleB2C: "B2C",
        RoleB2B: "B2B",
    }
}

export default GlobalVariables