import CryptoJS from 'crypto-js'
const secretKey = process.env.REACT_APP_FRONTEND_CRYPTO_KEY || "3js6b1epIjARh>-6'EIp4tw^Rvc*7~;yN?F/8GA+BXH|[~,eIG"
const backendCryptoKey = process.env.REACT_APP_BACKEND_CRYPTO_KEY || "YZkyiFATn8nova8X!VD=vci/"

export const convertEncrypted = (data) => {
    const jsonToString = JSON.stringify(data)
    const encryptedData = CryptoJS.AES.encrypt(jsonToString, secretKey).toString()
    return encryptedData;
}

export const convertDecrypted = (encryptedData, type = 'frontend') => {
    if (encryptedData !== undefined) {
        try {
            let bytes;
            if (type === 'frontend') {
                bytes = CryptoJS.AES.decrypt(encryptedData, secretKey)
            }
            else {
                bytes = CryptoJS.AES.decrypt(encryptedData, backendCryptoKey)
            }
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8)
            const decryptedObject = JSON.parse(decryptedString)
            return decryptedObject
        }
        catch (e) {
            console.error(e)
        }
    }
}


export const customChooseImage = (e) => {
    const selectedFile = e?.target?.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const maxSizeKB = 1024;

    const result = []

    for (let index = 0; index < selectedFile.length; index++) {
        if (selectedFile[index] !== undefined) {
            // if (!allowedTypes.includes(selectedFile[index]?.type)) {
            //     // e.target.value = null;
            //     result.push({ error: "Please Select Only JPG/PNG/JPEG Format", file: null })
            // }
            // else if ((selectedFile[index].size <= maxSizeKB * 1024) === false) {
            //     // e.target.value = null;
            //     result.push({ error: "Please choose Minimum 1MB image", file: null })
            // }
            // else {
                result.push({ error: "", file: selectedFile[index] })
            // }
        }
    }

    return result;
}

export const converToDate = (date, get = 'dateTime') => {
    const originalDate = new Date(date)

    const day = originalDate.getUTCDate()
    const month = originalDate.getUTCMonth() + 1
    const year = originalDate.getUTCFullYear()

    // Note: Padding single-digit day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day
    const formattedMonth = month < 10 ? `0${month}` : month

    // Note: Get time components
    const hours = originalDate.getUTCHours()
    const minutes = originalDate.getUTCMinutes()
    const seconds = originalDate.getUTCSeconds()

    // Note: Format time components with leading zeros if needed
    const formattedHours = hours < 10 ? `0${hours}` : hours
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

    // Note: Create formatted date string with time
    let formattedDateTime
    if (get !== 'dateTime') {
        formattedDateTime = `${formattedDay}-${formattedMonth}-${year}`
    }
    else {
        formattedDateTime = `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }

    return formattedDateTime
}


export const ChangeWebTitle = (title) => {
    document.title = `${title} | 3D Website` || "3D Website"
}