import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import CookiesStorageManager from "../classes/cookies-storage";
import GlobalVariables from "../utils/variables";
import AppUrls from "../routers/app-urls";


export const ProtectedRoute = ({ children }) => {

    const { status, data } = useAuth()
    const storage = new CookiesStorageManager()
    const getToken = storage.GetStorageItem(GlobalVariables.CookiesAuthAccessKey)

    if (getToken !== undefined && status === true && data !== null) {
        return <Navigate to={AppUrls.Url_Static_Home} />;
    }

    return children;
}


export const AuthProtectedRoute = ({ children }) => {

    const { status, data } = useAuth()
    const location = useLocation()
    const storage = new CookiesStorageManager()
    const getToken = storage.GetStorageItem(GlobalVariables.CookiesAuthAccessKey)

    // const venderRoute = [
    //     AppUrls.Url_Static_Home,
    //     AppUrls.Url_Static_Dashboard,
    //     AppUrls.Url_Not_Found,
    //     // AppUrls.Auth.Url_Static_Profile,
    //     // AppUrls.Url_Static_Contactus,
    //     AppUrls.B2B.Url_Static_Products,
    //     AppUrls.B2B.Url_Static_Orders,
    //     AppUrls.B2C.Url_Static_Products,
    //     AppUrls.B2C.Url_Static_Orders,
    // ]

    // if(data?.userType === GlobalVariables.Roles.RoleVender){
    //     if(!venderRoute.includes(location.pathname)){
    //         return <Navigate to={AppUrls.Url_Not_Found} />;
    //     }
    // }

    if (getToken === undefined && status === false && data === null) {
        return <Navigate to={AppUrls.Auth.Url_Static_Login} />
    }

    // if(location.pathname === '/'){
    //     return <Navigate to={AppUrls.Url_Static_Dashboard} />
    // }

    return children;
}