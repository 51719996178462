import { FaCheck, FaDisplay, FaEye, FaFileImage, FaHouseUser, FaPercent, FaRegChartBar, FaRegCreditCard, FaUser, FaUsers } from "react-icons/fa6";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { GrInProgress } from "react-icons/gr";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import { BiRupee } from "react-icons/bi";
import { BsCalendarDateFill, BsCartCheckFill } from "react-icons/bs";
import { MdCategory, MdEdit, MdLogout } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { LuSearch, LuSettings, LuBox, LuClipboardList, LuRefreshCcw, LuListTree } from "react-icons/lu";
import { HiClipboardList, HiMenu } from "react-icons/hi";
import { IoMdLink, IoMdTrash } from "react-icons/io";
import { FiBox, FiPlus } from "react-icons/fi";
import { ImNewTab } from "react-icons/im";
import { PiDotsThreeOutlineFill } from "react-icons/pi";

const FetchIcons = {
    Icon_FaDisplay: FaDisplay,
    Icon_LuSettings: LuSettings,
    Icon_FaRegChartBar: FaRegChartBar,
    Icon_FaPercent: FaPercent,
    Icon_FaUser: FaUser,
    Icon_FaUsers: FaUsers,
    Icon_LuSearch: LuSearch,
    Icon_LuBox: LuBox,
    Icon_FiBox: FiBox,
    Icon_HiMenu: HiMenu,
    Icon_IoClose: IoClose,
    Icon_LuClipboardList: LuClipboardList,
    Icon_MdLogout: MdLogout,
    Icon_BsCartCheckFill: BsCartCheckFill,
    Icon_MdCategory: MdCategory,
    Icon_MdEdit: MdEdit,
    Icon_IoMdTrash: IoMdTrash,
    Icon_FiPlus: FiPlus,
    Icon_BiRupee: BiRupee,
    Icon_HiClipboardList: HiClipboardList,
    Icon_BsCalendarDateFill: BsCalendarDateFill,
    Icon_FaRegCreditCard: FaRegCreditCard,
    Icon_RiCheckboxCircleFill: RiCheckboxCircleFill,
    Icon_RiCloseCircleFill: RiCloseCircleFill,
    Icon_GrInProgress: GrInProgress,
    Icon_FaEye: FaEye,
    Icon_LuRefreshCcw: LuRefreshCcw,
    Icon_FaFileImage: FaFileImage,
    Icon_TfiLayoutSliderAlt: TfiLayoutSliderAlt,
    Icon_LuListTree: LuListTree,
    Icon_FaHouseUser: FaHouseUser,
    Icon_FaCheck: FaCheck,
    Icon_IoMdLink: IoMdLink,
    Icon_ImNewTab: ImNewTab,
    Icon_PiDotsThreeOutlineFill: PiDotsThreeOutlineFill
}

export default FetchIcons