import React from 'react'
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom'
import AdminLayout from '../components/layouts/layouts'
import LoginPage from '../pages/auth/login'
import AuthLayout from '../components/layouts/auth-layout'
import AppUrls from './app-urls'
import PageNotFoundPage from '../pages/not-found'
import ScrollToTop from '../utils/functions/scrollTop'
import { ToastContainer } from 'react-toastify';
import { ProtectedRoute, AuthProtectedRoute } from '../private/ProtectedRoute'
import InitializationContext from '../context/initialization-context'
import SignUpPage from '../pages/auth/signup'
import ProjectPage from '../pages/projects'
import LogoutPage from '../pages/auth/logout'
import ProjectViewer from '../pages/viewer'

const ApplicationRouters = () => {
    return (
        <BrowserRouter>
            <InitializationContext>
                <ToastContainer
                    stacked
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                />
                <ScrollToTop>
                    <Routes>
                        <Route path='/auth' element={<ProtectedRoute><AuthLayout /></ProtectedRoute>}>
                            <Route path='login' element={<ProtectedRoute><LoginPage /></ProtectedRoute>} />
                            <Route path='signup' element={<ProtectedRoute><SignUpPage /></ProtectedRoute>} />
                            <Route path={"*"} element={<PageNotFoundPage />} />
                        </Route>

                        <Route path={AppUrls.Url_Static_Home} element={<AuthProtectedRoute><AdminLayout /></AuthProtectedRoute>}>
                            <Route path={AppUrls.Url_Static_Home} element={<AuthProtectedRoute><ProjectPage /></AuthProtectedRoute>} />
                            <Route path={AppUrls.Auth.Url_Static_Logout} element={<AuthProtectedRoute><LogoutPage /></AuthProtectedRoute>} />
                            <Route path={"*"} element={<PageNotFoundPage />} />
                            <Route path={AppUrls.Url_Not_Found} element={<PageNotFoundPage />} />
                        </Route>

                        <Route path={AppUrls.Url_Project_Viewer} element={<ProjectViewer rooms={true} />} />
                        <Route path={AppUrls.Url_Project_Room_Viewer} element={<ProjectViewer rooms={false} />} />
                    </Routes>
                </ScrollToTop>
            </InitializationContext>
        </BrowserRouter>
    )
}

export default ApplicationRouters