import Cookies from 'js-cookie'

class CookiesStorageManager {

    // ! Create a new item and add it to local storage.
    SetStorageItem(key = null, value = null, days = 1) {
        if (key !== null || value !== null) {
            Cookies.set(key, value, { expires: days })
            return true
        }
        else {
            return false
        }
    }

    // ! Retrieve a specific item from local storage.
    GetStorageItem(key = null) {
        if (key !== null) {
            return Cookies.get(key);
        }
        else {
            return false
        }
    }

    // ! Delete a specific item from local storage.
    DeleteStorageItem(key = null) {
        if (key !== null) {
            return Cookies.remove(key)
        }
        else {
            return false
        }
    }

    // ! Clear the local storage.
    // ClearStorage() {
    //     if (Cookies === undefined) {
    //         return true
    //     }
    //     else {
    //         return false
    //     }
    // }
}

export default CookiesStorageManager