import { ApiDelete, ApiGet, ApiPatch, ApiPost } from "../../services/apis-methods";
import BackEndUrls from "../../services/backend-urls";
import { handleApiResponse } from "../api-response";

class ProjectsManager {

    constructor() {
        this.getAllProjectUrl = BackEndUrls.Projects.read
        this.singleProjectUrl = BackEndUrls.Projects.single
        this.createProjectUrl = BackEndUrls.Projects.create
        this.updateThumbnailProjectUrl = BackEndUrls.Projects.update_thumbnail
        this.removeImageProjectUrl = BackEndUrls.Projects.delete_image
        this.removeProjectUrl = BackEndUrls.Projects.delete_project
    }

    async GetAllProjects() {
        try {
            const response = await ApiPost({ url: this.getAllProjectUrl })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async GetSingleProject(projectId) {
        try {
            const response = await ApiGet({ url: `${this.singleProjectUrl}/${projectId}` })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async CreateProject(data) {
        try {
            const response = await ApiPost({ url: this.createProjectUrl, body: data })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async UpdateProject(projectId, data) {
        try {
            const response = await ApiPatch({ url: `${this.updateThumbnailProjectUrl}/${projectId}`, body: data })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async DeleteProject(projectId) {
        try {
            const response = await ApiDelete({ url: `${this.removeProjectUrl}/${projectId}` })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }

    async DeleteProjectImage(projectId, imageIndex) {
        try {
            const response = await ApiDelete({ url: `${this.removeImageProjectUrl}/${projectId}/${imageIndex}` })
            return handleApiResponse(response)
        } catch (error) {
            return handleApiResponse(error)
        }
    }
}

export default ProjectsManager