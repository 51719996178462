import React from 'react'
import { Link } from 'react-router-dom'
import FetchIcons from '../../assets/icons'
import { useLocation } from 'react-router-dom'
import AppUrls from '../../routers/app-urls'
import { EachRender } from '../../utils/functions/each-rende'
import { Show } from '../../utils/functions/show-check'
import { useAuth } from '../../hooks/useAuth'

const SidebarLayout = () => {

    const location = useLocation()

    const [stateCollapseShow, setStateCollapseShow] = React.useState("hidden")
    // const { data } = useAuth()
    const data = {
        userType: "ADMIN"
    }

    const sidebarMenus = [
        {
            title: "Admin Pages",
            accessSection: ['ADMIN', 'USER'],
            menusCollection: [
                {
                    icon: FetchIcons.Icon_LuBox,
                    menuName: 'projects',
                    menuLink: AppUrls.Url_Static_Home,
                    accessMenu: ['ADMIN', 'USER']
                }
            ]
        },
        {
            title: "other",
            accessSection: ['ADMIN', 'USER'],
            menusCollection: [
                {
                    icon: FetchIcons.Icon_MdLogout,
                    menuName: 'logout',
                    menuLink: AppUrls.Auth.Url_Static_Logout,
                    accessMenu: ['ADMIN', 'USER']
                },
            ]
        },
    ]

    const handleCheckUrl = (menuLink) => {
        const menuParts = menuLink?.replaceAll("/", "")
        const pathParts = location.pathname.replaceAll("/", "")
        return menuParts === pathParts
    }


    return (
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
            <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">

                {/* Brand */}
                <Link className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" to="/">360 View Admin Panel</Link>

                {/* Toggler */}
                <button className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent" type="button" onClick={() => setStateCollapseShow("bg-white m-2 py-3 px-6")}>
                    <FetchIcons.Icon_HiMenu className='w-5 h-5' />
                </button>

                {/* Collapse */}
                <div className={"md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-0 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " + stateCollapseShow}>
                    {/* Collapse header */}
                    <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                        <div className="flex flex-wrap">
                            <div className="w-6/12">
                                <Link className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" to="/" >Notus React</Link>
                            </div>
                            <div className="w-6/12 flex justify-end">
                                <button type="button" className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent" onClick={() => setStateCollapseShow("hidden")} >
                                    <FetchIcons.Icon_IoClose className="w-5 h-5" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <EachRender of={sidebarMenus} render={(menus, index) => (
                        <Show>
                            <Show.When isTrue={data && menus && menus?.accessSection?.filter(item => item === data?.userType).length !== 0 ? true : false}>
                                <div key={index}>
                                    <hr className={`my-4 md:min-w-full ${index === 0 ? 'hidden md:block' : 'block'}`} />
                                    <h6 className="md:min-w-full text-gray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">{menus.title}</h6>
                                    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                                        <EachRender of={menus.menusCollection} render={(item, itemIndex) => (
                                            <Show>
                                                <Show.When isTrue={data && item && item?.accessMenu?.filter(item => item === data?.userType).length !== 0 ? true : false}>
                                                    <li className="items-center" key={itemIndex}>
                                                        <Link to={item.menuLink} className={`${handleCheckUrl(item.menuLink) ? "text-white bg-indigo-700 rounded" : "text-black hover:text-blue-500"} group pl-3 text-xs uppercase py-3 font-bold flex items-center`}>
                                                            <item.icon className={`${handleCheckUrl(item.menuLink) ? "text-white" : "text-black group-hover:text-blue-500"} mr-2 text-sm`} />
                                                            {item.menuName}
                                                        </Link>
                                                    </li>
                                                </Show.When>
                                            </Show>
                                        )} />
                                    </ul>
                                </div>
                            </Show.When>
                        </Show>
                    )} />
                </div>
            </div>
        </nav>
    )
}

export default SidebarLayout